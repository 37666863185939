(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/collapsible-group/assets/javascripts/collapsible-button.js') >= 0) return;  svs.modules.push('/components/tipsen/collapsible-group/assets/javascripts/collapsible-button.js');
"use strict";

const {
  ReactButton
} = svs.ui;
const {
  useCallback
} = React;
const CollapsibleButton = _ref => {
  let {
    value,
    onClick,
    children,
    isSelected = false,
    isDisabled = false,
    size = 200
  } = _ref;
  const handleOnClick = useCallback(() => {
    onClick(value);
  }, [value, onClick]);
  const classNames = ['margin-half', 'f-bold', 'pg_collapsible_selector_container__button'];
  if (isDisabled) {
    classNames.push('pg_collapsible_selector_container__button--disabled');
  }
  return React.createElement(ReactButton, {
    "aria-checked": isSelected,
    className: classNames.join(' '),
    disabled: isDisabled,
    onClick: handleOnClick,
    role: "radio",
    thin: true,
    transparent: true
  }, React.createElement("span", {
    className: "f-".concat(size)
  }, children));
};
setGlobal('svs.components.tipsen.collapsibleGroup.CollapsibleButton', CollapsibleButton);

 })(window);