(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/collapsible-group/assets/javascripts/collapsible-buttons-group.js') >= 0) return;  svs.modules.push('/components/tipsen/collapsible-group/assets/javascripts/collapsible-buttons-group.js');
"use strict";

const {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback
} = React;
const {
  If,
  Choose,
  When,
  Otherwise,
  ForEach
} = svs.components.lbUtils.reactConditionals;
const {
  CollapsibleButton
} = svs.components.tipsen.collapsibleGroup;
const CollapsibleButtonGroup = _ref => {
  var _buttonGroupGridEleme, _buttonGroupGridEleme2;
  let {
    setValue,
    value,
    allValues,
    title,
    promotedValues = [],
    collapsedSize = 9,
    gap = 6,
    minWidth = 85,
    render = v => v,
    clickCallback = () => {},
    isValueDisabled = () => false
  } = _ref;
  const buttonGroupGridElement = useRef();
  const [collapsedIndex, setCollapsedIndex] = useState(allValues.indexOf(value));
  const [showAllValues, setShowAllValues] = useState(false);
  const [visibleValues, setVisibleValues] = useState([]);
  const specialValues = useMemo(() => promotedValues ? promotedValues.filter(el => allValues.includes(el)) : [], [promotedValues, allValues]);
  const lowCount = useMemo(() => {
    const _lowCount = specialValues.length;
    return _lowCount > 0 ? _lowCount : collapsedSize;
  }, [specialValues.length, collapsedSize]);
  const useNrOfColumns = useCallback(gridWidth => Math.floor((gridWidth + gap) / (minWidth + gap)), [gap, minWidth]);
  const handleOnClick = useCallback(v => {
    setValue(v);
    clickCallback(v);
  }, [setValue, clickCallback]);
  const onToggleShowAllSystems = useCallback(() => {
    setShowAllValues(previousState => !previousState);
    setCollapsedIndex(allValues.indexOf(value));
  }, [allValues, value]);
  const hasSystemsToShow = visibleValues && visibleValues.length > 0;
  const isCollapsible = hasSystemsToShow && allValues.length > lowCount;
  const selectedIndex = hasSystemsToShow ? visibleValues.indexOf(value) : 0;
  const gridWidth = (_buttonGroupGridEleme = buttonGroupGridElement === null || buttonGroupGridElement === void 0 || (_buttonGroupGridEleme2 = buttonGroupGridElement.current) === null || _buttonGroupGridEleme2 === void 0 ? void 0 : _buttonGroupGridEleme2.clientWidth) !== null && _buttonGroupGridEleme !== void 0 ? _buttonGroupGridEleme : 0;
  const nrOfColumns = useNrOfColumns(gridWidth);
  useEffect(() => {
    if (allValues.length < lowCount) {
      setShowAllValues(true);
    }
  }, [allValues.length, setShowAllValues, lowCount]);
  useEffect(() => {
    if (showAllValues) {
      setVisibleValues(allValues);
    } else if (specialValues.includes(allValues[collapsedIndex])) {
      setVisibleValues(specialValues);
    } else {
      const displayCount = lowCount;
      const displayShift = Math.ceil(displayCount / 2) + Math.floor(nrOfColumns / 2) - collapsedIndex % nrOfColumns; 
      let minIndex = Math.max(collapsedIndex + displayShift - displayCount, 0);
      const maxIndex = Math.min(minIndex + displayCount, allValues.length);
      if (maxIndex - minIndex < displayCount) {
        minIndex = Math.max(maxIndex - displayCount, 0);
      }
      setVisibleValues(allValues.slice(minIndex, maxIndex));
    }
  }, [collapsedIndex, allValues, showAllValues, nrOfColumns, specialValues, lowCount]);
  return React.createElement("div", {
    className: "pg_collapsible_selector_container",
    ref: buttonGroupGridElement,
    role: "radiogroup"
  }, React.createElement(If, {
    condition: title && title.length > 0
  }, React.createElement("div", {
    className: "pg_collapsible_selector_container__header f-550"
  }, title)), React.createElement(Choose, null, React.createElement(When, {
    condition: hasSystemsToShow
  }, React.createElement("div", {
    className: "pg_collapsible_selector_container__grid"
  }, React.createElement(ForEach, {
    array: visibleValues,
    render: (value, index) => {
      const isDisabled = isValueDisabled(value);
      return React.createElement(CollapsibleButton, {
        isDisabled: isDisabled,
        isSelected: !isDisabled && index === selectedIndex,
        key: value,
        onClick: handleOnClick,
        value: value
      }, render(value));
    }
  })), React.createElement(If, {
    condition: isCollapsible
  }, React.createElement("div", {
    className: "pg_collapsible_selector_container__more_buttons"
  }, React.createElement(CollapsibleButton, {
    onClick: onToggleShowAllSystems,
    size: 300,
    value: "collapsible"
  }, React.createElement(Choose, null, React.createElement(When, {
    condition: showAllValues
  }, React.createElement("span", null, "Visa f\xE4rre -")), React.createElement(Otherwise, null, React.createElement("span", null, "Visa fler +"))))))), React.createElement(Otherwise, null, React.createElement("p", null, "Saknar v\xE4rden..."))));
};
setGlobal('svs.components.tipsen.collapsibleGroup.CollapsibleButtonGroup', CollapsibleButtonGroup);

 })(window);